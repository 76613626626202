const DEFAULT_BASE_URL = 'https://localhost:8000/'
const DEFAULT_PAGE_SIZE = 10

const logoPaths = {
  cloudKair: '',
  veritas: "/images/logo/veritas/black/full-black.png",
  sehteqArabia: '',
  speedyRecovery: "/images/logo/speedy-recovery/transparent-logo.png"
}

export const companies = {
  cloudKair: 'cloudKair',
  veritas: 'veritas',
  sehteqArabia: 'sehteqArabia',
  speedyRecovery: 'speedyRecovery'
}

export const company = process.env.REACT_APP_COMPANY_NAME ? process.env.REACT_APP_COMPANY_NAME : companies.veritas

export const appLogoPath = logoPaths[company]

export const urls = {
  BASE_URL: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : DEFAULT_BASE_URL,
  //BASE_URL: 'https://tebeya.com/',
  
  LOGGED_USER: '/api/user/me',

  CREATE_A_PATIENT: '/api/user/patientprofile/create',
  SEARCH_FOR_PATIENT: '/api/user/patientprofiles?searchfor=',
  GET_A_PATIENT: '/api/user/patientprofile/',
  LIST_PATIENTS: '/api/user/patientprofiles',

  LIST_DIAGNOSES: '/api/medical/codes/icds',
  LIST_SEARCHED_DIAGNOSES: 'api/medical/codes/icds/search',
  LIST_SEARCHED_PROCEDURES: 'api/medical/codes/cpts/search',
  LIST_SEARCHED_DRUGS: 'api/medical/codes/drugs/search',

  GET_USER_ENCOUNTERS: '/api/patient/encounter/patientid/',
  CREATE_NEW_ENCOUNTER: '/api/patient/encounter/create/',
  LIST_ENCOUNTERS: '/api/patient/encounters/',
  GET_ENCOUNTER: '/api/patient/encounter',
  UPDATE_GET_ENCOUNTER: '/api/patient/encounter/',
  ANALYSIS_ENCOUNTERS:'/api/analysis/encounter_state',
  DOCTOR_PRICES:'/api/analysis/doctor_prices',
  DRUGS_COUNT:'/api/analysis/most_used_drugs',
  ICDS_COUNT:'/api/analysis/most_used_icds',

  LIST_INSURANCES: '/api/insurance/insurances',
  LIST_FACILITIES:'/api/facility/facilities',
  
  UPLOAD_MRE: '/api/insurance/insurance/files/mreupload',
  UPLOAD_ICD_PRICES: '/api/insurance/insurance/files/icdupload',
  UPLOAD_CPT_PRICES: '/api/insurance/insurance/files/cptupload',
  UPLOAD_DRUG_PRICES: '/api/insurance/insurance/files/drugupload',

  GET_PATIENT_Diagnosis: '/api/patient/encounter/icd/patientid/',
  GET_PATIENT_Procedures: '/api/patient/encounter/cpt/patientid/',
  GET_PATIENT_Medications: '/api/patient/encounter/drug/patientid/',

  ALTERNATIVE_DRUG_OPTIONS: '/api/medical/codes/drugs/drugalternatives/',
  CHANGE_PASSWORD: '/api/user/user/change_password',
  GENERATE_INVOICE: '/api/erpnext/generate_invoice',

  FAVOURITE : '/api/user/user/favourite/'
};

export const MAX_NUM_OF_DIAGNOSES = 10;
export const MIN_NUMBER_OF_SEARCH = 3;
export const MAX_NUM_OF_PROCEDURES = 10;
export const INITIAL_TREATMENTS_INPUT = 1;
export const MAX_NUM_OF_TREATMENTS = 10;
export const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE ? process.env.REACT_APP_PAGE_SIZE : DEFAULT_PAGE_SIZE;
export const ENCOUNTER_SLICE_LENGTH = 5;

export const INITIAL_DIAGNOSIS_INPUT = [
  {
    index: 0,
    label: 'Primary Diagnosis',
    name: 'primary',
  },
  {
    index: 1,
    label: 'Secondary Diagnosis',
    name: 'secondary',
  },
];
export const INITIAL_PROCEDURES_INPUT = [
  {
    index: 0,
    label: 'Procedure 1',
    name: 'Procedure 0',
  },
];

export const INITIAL_TREATMENT_INPUT = [
  {
    index: 0,
    label: 'Treatment 1',
  },
];

export const INITIAL_TREATMENT_INPUT_VALUE = [
  {
    drug_dose: '',
    duration: '',
    frequency: '',
    drug: '',
    dosage_form: '',
    claimed_quantity: ''
  },
];

export const INITIAL_PATIENT_INSURANCE = {
  id: 1,
  name: "Out of Pocket",
  description: "Out of Pocket",
  register_id: "oop",
  use_mre: false
}

export const ENCOUNTER_ACTIONMSG = {
  Approved: 'Approved',
  Rejected: 'Rejected',
  Partially_accepted: 'Partially accepted',
  Pending_approval: 'Pending approval',
}

export const app_paths = {  
  LOGIN_AUTH : '/login',
  NEW_PATIENT: '/new-patient',
  NEW_ENCOUNTER: '/new-encounter/:patientID',
  MACHINE_LEARNING: '/machine-learning',
  PATIENT_PROFILE: '/patient/:patientID',
  DOCTOR_PROFILE: '/doctor/:doctorName',
  ANALYSIS: '/analysis-page',
  INSURANCE_ANALYSIS: '/insurance-analysis-page',
  SETTINGS: '/settings',
  INSURANCE_SETTINGS: '/insurance-settings'
}

export const reload_encounter = {
    FREQUENCY: 5000,
    DURATION: 120000
}

export const http_response_messages = {
  CREATE_ENCOUNTER: 'Encounter Created Successfuly',
  UPDATE_ENCOUNTER: 'Encounter is Updated',
  CREATE_PATIENT: 'Patient Added Successfully',
  CHANGE_PASSWORD: 'Password Changed Successfully',
  ERROR: 'something went wrong!'
}

export const toastify_status = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info'
}

export const searchByStateButtons = [
  {
    name: 'Approved',
    color: 'success'
  },
  {
    name: 'Partially accepted',
    color: 'warning'
  },
  {
    name: 'Rejected',
    color: 'error'
  },
  {
    name: 'Canceled',
    color: 'info'
  },
]