import MenuItem from '@mui/material/MenuItem';
import { useCallback } from 'react';

const handleReinitializeInputsLabel = (arrOfInputs, type) => {
  const startIndex = 0
  let count = startIndex + 1;
  const result = arrOfInputs.map((input) => {

    if (input && input.index > startIndex) {
      count++;
      return { ...input, label: (input.label = `${type} ${count}`) };
    } else {
      return input;
    }
  });
  return result;
};
// to remove encounter input check for the label then set translated label
export const RemoveInput = (setInputArray, setInputArrayValue, translatedLabel) => {
  const handleRemoveInput = useCallback((index) => {
    setInputArray((prevInputArray) => {
      let tempInputArray = [...prevInputArray];
      tempInputArray[index] = null;
      tempInputArray = handleReinitializeInputsLabel(tempInputArray, translatedLabel);
      return tempInputArray;
    });

    setInputArrayValue((prevInputArrayValue) => {
      const tempInputArrayValue = [...prevInputArrayValue];
      tempInputArrayValue[index] = null;
      return tempInputArrayValue;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return handleRemoveInput;
};

export const FilterNonNullInputs = (inputArray) => {
  const filterNonNullInputs = useCallback(() => {
    return inputArray.filter((input) => input !== null && input !== undefined);
  }, [inputArray]);
  return filterNonNullInputs();
};

export const DeleteItem = (setInputArray, property = null) => {
  const handleDeleteItem = useCallback((index) => {
    setInputArray((prevArray) => {
      const tempInputArray = [...prevArray];
      property !== null ? (tempInputArray[index][property] = null) : (tempInputArray[index] = null);
      return tempInputArray;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return handleDeleteItem;
};

export const AddMoreInputs = (setState, inputs, filteredInputsLength, labelPrefix, maxInputs) => {
  const inputsLength = inputs.length;
  if (filteredInputsLength < maxInputs) {
    setState((prevInputs) => [
      ...prevInputs,
      {
        label: `${labelPrefix} ${filteredInputsLength + 1}`,
        name: `${labelPrefix}${inputsLength}`,
        index: inputsLength,
      },
    ]);
  }
};

export const setInputOptionsAndValue = (selectedObjectProperty, targetName, setValueOptions, handleInputChange, index) => {
  const selectedOptionProperty = Array.isArray(selectedObjectProperty) ? selectedObjectProperty : [selectedObjectProperty];
  const event = new Event('change', { bubbles: true });
  const eventValue = selectedOptionProperty.length === 1 ? selectedOptionProperty[0] : '';
  Object.defineProperty(event, 'target', {
    value: { name: targetName, value: eventValue },
    enumerable: true,
  });
  const options = selectedOptionProperty.map((option) => (
    <MenuItem key={option} value={option}>
      {option.replace(/'/g, '')}
    </MenuItem>
  ));
  setValueOptions(options);
  handleInputChange(event, index);
};
