import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import NewEncounterFormStyle from './NewEncounterForm.module';
import useAxios from '../../../hooks/useAxios';
import AuthContext from '../../../contexts/auth-context';
import { useContext, useState, useEffect, memo } from 'react';
import { urls, MIN_NUMBER_OF_SEARCH, toastify_status } from '../../config/variables';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { validateCptCode } from '../../../Utils/validateHttpResponse';
import Toastify from '../../ui/Toastify';
import MedicalSelections from './MedicalSelections';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import SearchableSelect from '../../ui/SearchableSelect/SearchableSelect';
import { GlobalStyles } from '../../../constants/GlobalStyles';

const ProcedureSelect = memo(
  ({ procedureInput, handleSelectProcedure, procedures, clearSearch, handleDeleteProcedure, handleRemoveProcedureInput, isDisabled, favourite, updateFavourite }) => {
    const { label, index, name } = procedureInput;
    const classes = NewEncounterFormStyle();
    const { operation } = useAxios();
    const authCtx = useContext(AuthContext);
    const [procedureOptions, setProcedureOptions] = useState([]);
    const [loadingProcedureOptions, setLoadingProcedureOptions] = useState(false);
    const [procedureOptionsError, setProcedureOptionsError] = useState(undefined);
    const [searchQuery, setSearchQuery] = useState('');
    const [prevSearchQuery, setPrevSearchQuery] = useState('');
    const [selectInputLabel, setSelectInputLabel] = useState('Please search first before selecting.');
    // favourite Procedures
    const [favouriteProcedures, setFavouriteProcedures] = useState([])
    const [openFavouriteProceduresDialog, setOpenFavouriteProceduresDialog] = useState(false);
    // check if the selected Procedure is a favourite Procedure or no
    const [selectedFavouriteProcedure, setsSelectedFavouriteProcedure] = useState(false);
    const [loadingFavourite, setLoadingFavourite] = useState(false);
    const [selectedProcedure, setSelectedProcedure] = useState('');

    useEffect(() => {
      if(Array.isArray(favourite.cpts)) {
        setFavouriteProcedures(favourite.cpts)
        setLoadingFavourite(false)
      }
    }, [favourite])

    const handleSetProceduresOptions = (resProcedureOptions) => {
      handleDeleteSelectedProcedure()
      setLoadingProcedureOptions(false)
      if(validateCptCode(resProcedureOptions)) {
        setProcedureOptions(resProcedureOptions);
        setSelectedProcedure('')
        resProcedureOptions.length > 0
          ? setSelectInputLabel(<Typography sx={classes.found_options}>{`Search or select from (${resProcedureOptions.length}) options...`}</Typography>)
          : setSelectInputLabel(<Typography sx={classes.not_found_options}>No Procedures found for "{searchQuery}".</Typography>);  
        } else {
          console.log('invalid procedure response')
          Toastify({message: 'invalid procedure response', state: toastify_status.ERROR})
        }
      };

    const handleSubmit = () => {
      if (searchQuery.length >= MIN_NUMBER_OF_SEARCH) {
        setProcedureOptionsError('');
        setLoadingProcedureOptions(true)
        operation(
          {
            method: 'POST',
            url: urls.LIST_SEARCHED_PROCEDURES,
            headers: { Authorization: `token ${authCtx.token}` },
            data: {"searchfor": searchQuery},
          },
          handleSetProceduresOptions,
          handleSetProcedureOptionsError
        );
      }
    };

    const handleSetProcedureOptionsError = (error) => {
      setLoadingProcedureOptions(false)
      setProcedureOptionsError(error)
    }

    const handleSearch = (event) => {
      const value = event.target.value;
      if (typeof value !== 'undefined') {
        setSearchQuery(value);
      }
    };

    // Note: this clearSearchQuery argument is a simple string passed over from handleClearDiagnose InputAdornment below
    const handleClearProcedure = (clearSearchQuery) => {
      if(clearSearchQuery) {
        setSearchQuery('');
      }
      handleDeleteProcedure(index);
      setProcedureOptions([]);
      setProcedureOptionsError(undefined);
      setSelectInputLabel('Please search first before selecting.');
      setsSelectedFavouriteProcedure(false)
      setSelectedProcedure('')
    }

    useEffect(() => {
      setSearchQuery('');
      setProcedureOptions([]);
      setProcedureOptionsError(undefined);
      setSelectInputLabel('Please search first before selecting.');
      setSelectedProcedure('')
    }, [clearSearch]);

    /* use effect to clear Diagnoses in case the user removed or changed the search query
     after get diagnosee options or select diagnose */
     useEffect(() => {
      if (
        (procedures[index] && 
         (!searchQuery || (prevSearchQuery.length >= 3 && searchQuery.length === 2))) ||
        (procedureOptions.length > 0 && searchQuery.length <= 2)
      ) {
        handleClearProcedure();
      }
    // eslint-disable-next-line
    }, [procedures, searchQuery, prevSearchQuery, procedureOptions]);

    useEffect(() => {
      setPrevSearchQuery(searchQuery)
    }, [searchQuery])

    // delete selected Procedure after Re-search for options
    const handleDeleteSelectedProcedure = () => {
      if(procedures[index]) {
        handleDeleteProcedure(index);
      }
    }

    const handleOpenFavouriteProceduresPopup = () => {
      setOpenFavouriteProceduresDialog(true);
    };

    const handleCloseFavouriteProceduresPopup = () => {
      setOpenFavouriteProceduresDialog(false);
    };

    const selectFavouriteProcedure = (cpt) => {
      const event = {target: {name, value: cpt.cptcode}}
      handleSelectProcedure(event, index)
      setOpenFavouriteProceduresDialog(false)
      setSearchQuery(cpt.shortdescription)
      setProcedureOptions([{...cpt}])
      setSelectedProcedure(cpt)
    }

    // check if the selected Procedure is a favourite Procedure or no
    useEffect(() => {
      if (procedures[index] && procedures[index].cpt) {
        setsSelectedFavouriteProcedure(favouriteProcedures.find((cpt) => cpt.cptcode === procedures[index].cpt));
      }
    }, [procedures, index, favouriteProcedures]);

    const addFavouriteProcedure = () => {
      const favouriteProceduresList = favouriteProcedures.map((cpt) => cpt.cptcode)
      const updatesProceduresList = [...favouriteProceduresList, procedures[index].cpt]
      const data = {'cpts': updatesProceduresList}
      updateFavourite(data)
      setLoadingFavourite(true)
    }

    const RemoveFavouriteProcedure = () => {
      const updatesProceduresList = favouriteProcedures.map((cpt) => cpt.cptcode).filter((cpt) => cpt !== procedures[index].cpt)
      const data = {'cpts': updatesProceduresList}
      updateFavourite(data)
      setLoadingFavourite(true)
    }

    const selectProcedure = (cpt) => {
      const event ={target: {name, value: cpt.cptcode}}
      handleSelectProcedure(event, index)
      setSelectedProcedure(cpt)
    };

    return (
      <Box>
        <Box sx={classes.input_label_container}>
          <Box sx={classes.label_and_remove_input_box}>
            <InputLabel sx={classes.input_label}>{label}</InputLabel>
            {index !== 0 && (
              <IconButton size="small" onClick={() => handleRemoveProcedureInput(index)}>
                <Tooltip title="Remove Input">
                  <RemoveIcon sx={classes.remove_icon} />
                </Tooltip>
              </IconButton>
            )}
          </Box>
          <Box sx={classes.input_label_container_buttons_box}>
              <Button
                sx={classes.open_dialog_button}
                onClick={handleOpenFavouriteProceduresPopup}
                disabled={isDisabled || (!loadingFavourite && favouriteProcedures.length === 0)}
              >
                <Typography
                 sx={[classes.dialog_button_title, {opacity: loadingFavourite ? GlobalStyles.opacity.half : GlobalStyles.opacity.full}]}>
                  Favorite Procedures
                </Typography>
                {loadingFavourite && (
                  <CircularProgress
                    size={14}
                    sx={classes.input_label_container_button_circular_progress}
                  />
                )}
              </Button>
            </Box>
        </Box>


        <Box sx={classes.select_inputs_container}>
          <TextField
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
            disabled={isDisabled}
            variant="outlined"
            InputProps={{
              startAdornment: searchQuery && (
                <InputAdornment position="start">
                  <IconButton onClick={() => handleClearProcedure('clearSearchQuery')} sx={classes.clear_button} disabled={procedures.length < index}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSubmit} sx={classes.search_button} disabled={searchQuery.length < MIN_NUMBER_OF_SEARCH}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={searchQuery}
            onChange={handleSearch}
            placeholder={`Search for at least ${MIN_NUMBER_OF_SEARCH} characters`}
            sx={classes.search_input}
            size="small"
            error={!!procedureOptionsError}
            helperText={procedureOptionsError ? procedureOptionsError.message : ''}
          />

          <Box sx={classes.searchable_and_star_box_container}>
            <SearchableSelect
              value={selectedProcedure}
              onChange={selectProcedure}
              options={loadingProcedureOptions ? [] : procedureOptions}
              placeholder={selectInputLabel}
              disabled={isDisabled || procedureOptions.length === 0 || loadingProcedureOptions}
              getOptionLabel={(option) => option.shortdescription}
              getOptionValue={(option) => option.cptcode}
              loading={loadingProcedureOptions}
            />

            <Box sx={classes.star_box}>
              {loadingFavourite ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton
                  disabled={!procedures[index]}
                  onClick={selectedFavouriteProcedure ? RemoveFavouriteProcedure : addFavouriteProcedure}
                >
                  <Tooltip title={selectedFavouriteProcedure ? 'Remove From Favourites' : 'Add To Favourites'}>
                    {selectedFavouriteProcedure ? <StarIcon /> : <StarBorderIcon />}
                  </Tooltip>
                </IconButton>
              )}
          </Box>
        </Box>

        </Box>
          <MedicalSelections
          selectionType='cpt'
          title='Favourite Procedure'
          open={openFavouriteProceduresDialog}
          handleClose={handleCloseFavouriteProceduresPopup}
          loading={false}
          data={favouriteProcedures}
          selectOption={selectFavouriteProcedure}
            />
      </Box>
    );
  }
);

export default ProcedureSelect;
