import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EncounterCardStyle from './EncounterCard.module';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { validateMreMessages } from '../../../Utils/validateMedicalCodeMessages';
import { globalColors } from '../../../constants/ColorsPalette';

const renderIcdActionMessages = ({ actionMessages }) => {
  const classes = EncounterCardStyle();

  if (validateMreMessages(actionMessages)) {
    const state = actionMessages["status"]["state"];
    if (state === 'approved' && !actionMessages["status"]["messages"]["extra_msgs"]) {
      return null;
    } else if (state === 'approved' || state === 'rejected') {
      const title = {
        'extra_msgs': 'More Messages',
      }

      const objectKeys = Object.keys(actionMessages['status']['messages'])
      // map throw action messages.status.messages wich is object contain list of messages, to get the keys of this messages
      // and the result like ["extra_msgs"]
      return (
        <Box>
          {objectKeys.map((key, i) => {
            // chack if messages list more than 0 this mean there is message
            return actionMessages['status']['messages'][key].length > 0 && (
              <Box key={i}>
                {/* show icdcpt action message title */}
                <Typography sx={classes.medicalCode_message_title}>{title[key]}</Typography>
                {/* show icdcpt action message content */}
                {actionMessages['status']['messages'][key].map((msg, i) => {
                  return <Box
                  key={i}
                  sx={[
                    classes.medicalCode_messages_point,
                    {
                      color: state === 'rejected'
                        ? globalColors.darkMaroon
                        : globalColors.jetBlack
                    }
                  ]}
                >
                    <FiberManualRecordIcon sx={classes.medicalCode_dot_icon} />
                    <Typography style={classes.medicalCode_message}>{msg.msg}</Typography>
                  </Box>
                })}
              </Box>
            )
          })}
        </Box>
      )
    } else {
      // show any state that is not approved or rejected
      return <Typography sx={classes.medicalCode_message_title}>{state}</Typography>;
    }
  } else {
    return (
      <Box sx={[classes.medicalCode_messages_point, classes.error_msg]}>
        <FiberManualRecordIcon sx={classes.medicalCode_dot_icon} />
        <Typography>Message cannot be shown</Typography>
      </Box>
    );
  }
};

export default renderIcdActionMessages;
