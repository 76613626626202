import Joi from 'joi';

const TreatmentFormSchema = Joi.object({
    drug_dose: Joi.number().min(1).max(1000).label('Medication Dose'),
    frequency: Joi.number().min(1).max(1000).label('Daily Frequency'),
    duration: Joi.number().min(1).max(1000).label('Duration In Days'),
    claimed_quantity: Joi.number().min(1).max(1000).label('Claimed Quantity'),
});

export default TreatmentFormSchema;
