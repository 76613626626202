export const nationalities = [
    { nationality: 'Afghanistan' },
    { nationality: 'Albania' },
    { nationality: 'Algeria' },
    { nationality: 'Andorra' },
    { nationality: 'Angola' },
    { nationality: 'Antigua and Barbuda' },
    { nationality: 'Argentina' },
    { nationality: 'Armenia' },
    { nationality: 'Australia' },
    { nationality: 'Austria' },
    { nationality: 'Azerbaijan' },
    { nationality: 'Bahamas' },
    { nationality: 'Bahrain' },
    { nationality: 'Bangladesh' },
    { nationality: 'Barbados' },
    { nationality: 'Belarus' },
    { nationality: 'Belgium' },
    { nationality: 'Belize' },
    { nationality: 'Benin' },
    { nationality: 'Bhutan' },
    { nationality: 'Bolivia' },
    { nationality: 'Bosnia and Herzegovina' },
    { nationality: 'Botswana' },
    { nationality: 'Brazil' },
    { nationality: 'Brunei' },
    { nationality: 'Bulgaria' },
    { nationality: 'Burkina Faso' },
    { nationality: 'Burundi' },
    { nationality: 'Cabo Verde' },
    { nationality: 'Cambodia' },
    { nationality: 'Cameroon' },
    { nationality: 'Canada' },
    { nationality: 'Central African Republic' },
    { nationality: 'Chad' },
    { nationality: 'Chile' },
    { nationality: 'China' },
    { nationality: 'Colombia' },
    { nationality: 'Comoros' },
    { nationality: 'Congo (Congo-Brazzaville)' },
    { nationality: 'Costa Rica' },
    { nationality: 'Croatia' },
    { nationality: 'Cuba' },
    { nationality: 'Cyprus' },
    { nationality: 'Czechia (Czech Republic)' },
    { nationality: 'Denmark' },
    { nationality: 'Djibouti' },
    { nationality: 'Dominica' },
    { nationality: 'Dominican Republic' },
    { nationality: 'Ecuador' },
    { nationality: 'Egypt' },
    { nationality: 'El Salvador' },
    { nationality: 'Equatorial Guinea' },
    { nationality: 'Eritrea' },
    { nationality: 'Estonia' },
    { nationality: 'Eswatini (fmr. "Swaziland")' },
    { nationality: 'Ethiopia' },
    { nationality: 'Fiji' },
    { nationality: 'Finland' },
    { nationality: 'France' },
    { nationality: 'Gabon' },
    { nationality: 'Gambia' },
    { nationality: 'Georgia' },
    { nationality: 'Germany' },
    { nationality: 'Ghana' },
    { nationality: 'Greece' },
    { nationality: 'Grenada' },
    { nationality: 'Guatemala' },
    { nationality: 'Guinea' },
    { nationality: 'Guinea-Bissau' },
    { nationality: 'Guyana' },
    { nationality: 'Haiti' },
    { nationality: 'Honduras' },
    { nationality: 'Hungary' },
    { nationality: 'Iceland' },
    { nationality: 'India' },
    { nationality: 'Indonesia' },
    { nationality: 'Iran' },
    { nationality: 'Iraq' },
    { nationality: 'Ireland' },
    { nationality: 'Italy' },
    { nationality: 'Jamaica' },
    { nationality: 'Japan' },
    { nationality: 'Jordan' },
    { nationality: 'Kazakhstan' },
    { nationality: 'Kenya' },
    { nationality: 'Kiribati' },
    { nationality: 'Kuwait' },
    { nationality: 'Kyrgyzstan' },
    { nationality: 'Laos' },
    { nationality: 'Latvia' },
    { nationality: 'Lebanon' },
    { nationality: 'Lesotho' },
    { nationality: 'Liberia' },
    { nationality: 'Libya' },
    { nationality: 'Liechtenstein' },
    { nationality: 'Lithuania' },
    { nationality: 'Luxembourg' },
    { nationality: 'Madagascar' },
    { nationality: 'Malawi' },
    { nationality: 'Malaysia' },
    { nationality: 'Maldives' },
    { nationality: 'Mali' },
    { nationality: 'Malta' },
    { nationality: 'Marshall Islands' },
    { nationality: 'Mauritania' },
    { nationality: 'Mauritius' },
    { nationality: 'Mexico' },
    { nationality: 'Micronesia' },
    { nationality: 'Moldova' },
    { nationality: 'Monaco' },
    { nationality: 'Mongolia' },
    { nationality: 'Montenegro' },
    { nationality: 'Morocco' },
    { nationality: 'Mozambique' },
    { nationality: 'Myanmar (formerly Burma)' },
    { nationality: 'Namibia' },
    { nationality: 'Nauru' },
    { nationality: 'Nepal' },
    { nationality: 'Netherlands' },
    { nationality: 'New Zealand' },
    { nationality: 'Nicaragua' },
    { nationality: 'Niger' },
    { nationality: 'Nigeria' },
    { nationality: 'North Korea' },
    { nationality: 'North Macedonia' },
    { nationality: 'Norway' },
    { nationality: 'Oman' },
    { nationality: 'Pakistan' },
    { nationality: 'Palau' },
    { nationality: 'Palestine State' },
    { nationality: 'Panama' },
    { nationality: 'Papua New Guinea' },
    { nationality: 'Paraguay' },
    { nationality: 'Peru' },
    { nationality: 'Philippines' },
    { nationality: 'Poland' },
    { nationality: 'Portugal' },
    { nationality: 'Qatar' },
    { nationality: 'Romania' },
    { nationality: 'Russia' },
    { nationality: 'Rwanda' },
    { nationality: 'Saint Kitts and Nevis' },
    { nationality: 'Saint Lucia' },
    { nationality: 'Saint Vincent and the Grenadines' },
    { nationality: 'Samoa' },
    { nationality: 'San Marino' },
    { nationality: 'Sao Tome and Principe' },
    { nationality: 'Saudi Arabia' },
    { nationality: 'Senegal' },
    { nationality: 'Serbia' },
    { nationality: 'Seychelles' },
    { nationality: 'Sierra Leone' },
    { nationality: 'Singapore' },
    { nationality: 'Slovakia' },
    { nationality: 'Slovenia' },
    { nationality: 'Solomon Islands' },
    { nationality: 'Somalia' },
    { nationality: 'South Africa' },
    { nationality: 'South Korea' },
    { nationality: 'South Sudan' },
    { nationality: 'Spain' },
    { nationality: 'Sri Lanka' },
    { nationality: 'Sudan' },
    { nationality: 'Suriname' },
    { nationality: 'Sweden' },
    { nationality: 'Switzerland' },
    { nationality: 'Syria' },
    { nationality: 'Taiwan' },
    { nationality: 'Tajikistan' },
    { nationality: 'Tanzania' },
    { nationality: 'Thailand' },
    { nationality: 'Timor-Leste' },
    { nationality: 'Togo' },
    { nationality: 'Tonga' },
    { nationality: 'Trinidad and Tobago' },
    { nationality: 'Tunisia' },
    { nationality: 'Turkey' },
    { nationality: 'Turkmenistan' },
    { nationality: 'Tuvalu' },
    { nationality: 'Uganda' },
    { nationality: 'Ukraine' },
    { nationality: 'United Arab Emirates' },
    { nationality: 'United Kingdom' },
    { nationality: 'United States of America' },
    { nationality: 'Uruguay' },
    { nationality: 'Uzbekistan' },
    { nationality: 'Vanuatu' },
    { nationality: 'Venezuela' },
    { nationality: 'Vietnam' },
    { nationality: 'Yemen' },
    { nationality: 'Zambia' },
    { nationality: 'Zimbabwe' }
  ];