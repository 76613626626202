
const SearchableSelectStyle = () => {
    return {
        container: { 
            width: '100%',
        },
        focused_control: {
            height: '40px',
            borderColor: 'rgb(74, 187, 167)',
            boxShadow: '0 0 0 1px rgb(74, 187, 167)',
            '&:hover': {
                borderColor: 'rgb(74, 187, 167)',
            },
        },
        not_focused_control: {
            height: '40px',
            borderColor: 'rgb(196, 196, 196)',
            '&:hover': {
                borderColor: 'rgb(33, 33, 33)',
            },
        },
        menu: {
            zIndex: 9999,
        },
        focused_option: {
            backgroundColor: 'rgb(245, 245, 245)',
            color: 'black',
            zIndex: 9999,
            cursor: 'pointer'
        },
        selected_option: {
            backgroundColor: 'rgb(241, 250, 248)',
            color: 'black',
            zIndex: 9999,
        },
        not_focused_option: {
            color: 'black',
            zIndex: 9999,
        },
        loading_indicator: {
            fontSize: '10px',
            color: 'rgb(74, 187, 167)',
        }
    };
};

export default SearchableSelectStyle;
