
// function to check object and msg inside extra_msgs
const isValidMsg = (msgObj) => {
    return (
        typeof msgObj === 'object' &&
        typeof msgObj.msg === 'string' &&
        msgObj.msg.trim().length > 0
    );
};

// function to validate MRE action messages object
export const validateMreMessages = (data) => {
    if (data && typeof data === 'object' && data["status"]) {
        const status = data["status"];
        if (status && typeof status === 'object' && status["state"]) {
            if (status["state"] === 'rejected') {
                const messages = status["messages"];
                if (messages && typeof messages === 'object') {
                    const extra_msgs = messages["extra_msgs"];
                    if (Array.isArray(extra_msgs)) {
                        const isValidExtra_msgs = extra_msgs.every(isValidMsg);
                        return isValidExtra_msgs;
                    }
                }
            } else if (status["state"] === 'approved') {
                const messages = status["messages"];
                if (messages && typeof messages === 'object' && messages["extra_msgs"]) {  // <--- if approved and contain extra_msgs then validate this message
                    const extra_msgs = messages["extra_msgs"];
                    if (Array.isArray(extra_msgs)) {
                        // check each object in extra_msgs
                        const isValidExtra_msgs = extra_msgs.every(isValidMsg);
                        return isValidExtra_msgs;
                    }
                } else { // <--- if approved but do not contain extra_msgs return true because it is empty object {}
                    return true
                }
            } else {
                return true
            }
        }
    }
    return false;
};

// function to validate PBM action messages object
export const validatePbmMessages = (data) => {
    if (data && typeof data === 'object' && data["status"]) {
        const status = data["status"];
        if (status && typeof status === 'object' && status["state"]) {
            if (status["state"] === 'approved' || status["state"] === 'rejected') {
                const messages = status["messages"];
                if (messages && typeof messages === 'object') {
                    const td_msgs = messages["td_msg"];
                    const dci_msgs = messages["dci_msg"];
                    const ddi_msgs = messages["ddi_msg"];
                    const gi_msgs = messages["gi_msg"];
                    const extra_msgs = messages["extra_msgs"];
                    const di_msgs = messages["di_msg"];

                    // check if all of td, dci, ddi, gi, extra messages are exist then validate
                    if (td_msgs && dci_msgs && ddi_msgs && gi_msgs && extra_msgs && di_msgs) {
                        if (
                            Array.isArray(td_msgs) &&
                            Array.isArray(dci_msgs) &&
                            Array.isArray(ddi_msgs) &&
                            Array.isArray(gi_msgs) &&
                            Array.isArray(extra_msgs) &&
                            Array.isArray(di_msgs)
                        ) {
                            // check each object in td_msgs
                            const isValidTdMsgs = td_msgs.every(isValidMsg);

                            // check each object in dci_msgs
                            const isValidDciMsgs = dci_msgs.every(isValidMsg);

                            // check each object in ddi_msgs
                            const isValidDdiMsgs = ddi_msgs.every(isValidMsg);

                            // check each object in gi_msgs
                            const isValidGiMsgs = gi_msgs.every(isValidMsg);

                            // check each object in extra_msgs
                            const isValidExtra_msgs = extra_msgs.every(isValidMsg);

                            // check each object in di_msgs
                            const isValidDi_msgs = di_msgs.every(isValidMsg);

                            return isValidTdMsgs && isValidDciMsgs && isValidDdiMsgs && isValidGiMsgs && isValidExtra_msgs && isValidDi_msgs;
                        }
                    } else {
                        // check if only extra messages are exist then validate
                        if (Array.isArray(extra_msgs)) {
                            // check each object in extra_msgs
                            const isValidExtra_msgs = extra_msgs.every(isValidMsg);
                            return isValidExtra_msgs;
                        }
                    }
                }
            } else {
                return true
            }
        }
    }
    return false;
};