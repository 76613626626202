import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EncounterCardStyle from './EncounterCard.module';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { validatePbmMessages } from '../../../Utils/validateMedicalCodeMessages';
import { globalColors } from '../../../constants/ColorsPalette';

const RenderDrugActionMessages = ({ actionMessages }) => {
  const classes = EncounterCardStyle();

  if (validatePbmMessages(actionMessages)) {
    const state = actionMessages["status"]["state"];

    if (state === 'approved' || state === 'rejected') {
      const title = {
        'td_msg': 'Therapeutic Duplications',
        'dci_msg': 'Drug Counter Indications',
        'ddi_msg': 'Drug to Drug Interactions',
        'gi_msg': 'Gender Interactions',
        'di_msg': 'Drug Indications',
        'extra_msgs': 'More Messages',
      }

      const objectKeys = Object.keys(actionMessages['status']['messages'])
      // map throw action messages.status.messages wich is object contain list of messages, to get the keys of this messages
      // and the result like ["di_msg", "gi_msg", "td_msg", "dci_msg", "ddi_msg", "extra_msgs"]

      return (
        <Box>
          {objectKeys.map((key, i) => {
            // chack if messages list more than 0 this mean there is message
            return actionMessages['status']['messages'][key].length > 0 && (
              <Box
                key={i}
                sx={[
                  classes.medicalCode_message_box,
                  {
                    borderColor: state === 'rejected'
                      ? globalColors.red
                      : globalColors.greenPea,
                  },
                ]}
              >

                {/* show drug action message title */}
                <Typography sx={classes.medicalCode_message_title}>{title[key]}</Typography>
                {/* show drug action message content */}
                {actionMessages['status']['messages'][key].map((msg, i) => {
                  return (
                    <Box
                      key={i}
                      sx={[
                        classes.medicalCode_messages_point,
                        {
                          color: state === 'rejected'
                            ? globalColors.darkMaroon
                            : globalColors.jetBlack
                        }
                      ]}
                    >
                      <FiberManualRecordIcon sx={classes.medicalCode_dot_icon} />
                      <Typography sx={classes.medicalCode_message}>{msg.msg}</Typography>
                      {msg['Severity level'] && (
                        <>
                          <PlayArrowIcon sx={classes.severitylevelIcon} />
                          <Typography sx={classes.medicalCode_message}> Severity level: {msg['Severity level']}</Typography>
                        </>
                      )}
                    </Box>
                  )
                })}
              </Box>
            )
          })}
        </Box>
      )
    } else {
      // show any state that not approved or pending approval or rejected
      return <Typography sx={classes.medicalCode_message_title}>{state}</Typography>;
    }
  } else {
    return <Box sx={[classes.medicalCode_messages_point, classes.error_msg]}>
    <FiberManualRecordIcon sx={classes.medicalCode_dot_icon} />
    <Typography>Message cannot be shown</Typography> 
  </Box>
  }
};

export default RenderDrugActionMessages;