import React, { useMemo } from 'react';
import Select from 'react-select';
import SearchableSelectStyle from './SearchableSelect.module';

const SearchableSelect = ({
    value,
    onChange,
    options,
    placeholder,
    disabled,
    getOptionLabel,
    getOptionValue,
    searchable,
    loading
}) => {
    const classes = SearchableSelectStyle();

    const SearchableSelect = useMemo(() => (
        <Select
            styles={{
                control: (baseStyles, state) => [
                    { ...baseStyles },
                    state.isFocused ? classes.focused_control : classes.not_focused_control
                ],
                option: (provided, state) => [
                    { ...provided },
                    state.isFocused
                        ? classes.focused_option
                        : state.isSelected
                        ? classes.selected_option
                        : classes.not_focused_option
                ],
                menu: (provided) => [{ ...provided }, classes.menu],
                loadingIndicator: (provided) => [{...provided}, classes.loading_indicator]
            }}
            placeholder={placeholder}
            value={value || null}
            options={options}
            onChange={onChange}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isSearchable={searchable}
            isDisabled={disabled}
            isLoading={loading}
        />
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [options, value]);

    return (
        <div style={classes.container}>
            {SearchableSelect}
        </div>
    );
};

export default SearchableSelect;
